import { NgModule, inject, provideAppInitializer } from '@angular/core';
import {CommonModule} from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducers} from '@app/store/root-state';
import {RootEffects} from '@app/store/root-effects';
import {ApiConfigurationsService} from '@app/store/api-configurations.service';
import {ClientSlicesStoreModule} from '@app/store/client-slices/client-slices-store.module';
import {MessageStoreModule} from '@app/store/messages/message-module';
import {FaxeStoreModule} from '@app/store/faxe/faxe-store-module';

@NgModule({ imports: [CommonModule,
        ClientSlicesStoreModule,
        MessageStoreModule,
        FaxeStoreModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true,
                strictActionWithinNgZone: true,
                strictActionTypeUniqueness: true,
            },
        }),
        EffectsModule.forRoot([RootEffects])], providers: [
        provideAppInitializer(() => {
        const initializerFn = ((apiConf: ApiConfigurationsService) => () => apiConf.init())(inject(ApiConfigurationsService));
        return initializerFn();
      }),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class RootStoreModule {}
