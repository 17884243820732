import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {RaceClass} from '@app/rest-client/faxe/models/race-class';
import {addManyRaceClasses, removeAllRaceClasses} from '@app/store/faxe/race-class/race-class.actions';

export const identifier = (a: RaceClass): string => a.raceClassGuid;

export const adapter: EntityAdapter<RaceClass> = createEntityAdapter<RaceClass>({
  selectId: identifier,
});

export type RaceClassEntityState = EntityState<RaceClass>;

export const initialState: RaceClassEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(addManyRaceClasses, (state, {models}) => adapter.addMany(models, state)),
  on(removeAllRaceClasses, (state) => adapter.removeAll(state)),
);

export const raceClassReducer: (state: (RaceClassEntityState | undefined), action: Action) =>
  EntityState<RaceClass> = (state: RaceClassEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllRaceClass = selectAll;


