import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {LandingFacadeService} from '@app/components/landing/landing-facade.service';
import {MenuItem} from '@app/components/shared/button-menue/model/menu-item';
import {takeUntil} from 'rxjs/operators';
import {RidersService} from '@app/rest-client/faxe/services/riders.service';
import {ResultCodeFacadeService} from '@app/services/result-code/result-code-facade.service';
import {FenceFacadeService} from '@app/services/fence/fence-facade.service';
import {RaceClassFacadeService} from '@app/services/race-class/race-class-facade.service';
import {ClubLogoFacadeService} from '@app/services/club-logo/club-logo-facade.service';

@Component({
    selector: 'faxe-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    standalone: false
})
export class LandingComponent implements OnInit, OnDestroy {
  mnuStarter = 'Start';
  mnuStop = 'Målgång';
  mnuResult = 'Resultat';
  mnuFenseResult = 'Hinderbedömning';
  menuItemsFunction: MenuItem<string>[];
  private unsubscribe$ = new Subject<void>();
  private componentId: 'LandingComponent';

  constructor(
    private facade: LandingFacadeService,
    private router: Router,
    private readonly ridersService: RidersService,
    private readonly resultCodeFacadeService: ResultCodeFacadeService,
    private readonly fenceFacadeService: FenceFacadeService,
    private readonly raceClassFacadeService: RaceClassFacadeService,
    private readonly clubLogofacadeServcie: ClubLogoFacadeService) {
  }

  ngOnInit(): void {
    this.fetchAllClubLogos();
    this.fetchFence();
    this.fetchRaceClasses();
    this.fetchAllResultCodes();
  }

  fetchAllClubLogos(): void {
    this.clubLogofacadeServcie
      .fetchAllClubLogos(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  fetchAllResultCodes(): void {
    this.resultCodeFacadeService
      .fetchAllResultCodes(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  fetchFence(): void {
    this.fenceFacadeService
      .fetchFence(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  fetchRaceClasses(): void {
    this.raceClassFacadeService
      .fetchRaceClass(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  menuSkapaClick($event: string) {
    this.router.navigate([`/${$event}/`]);
  }

}
