import * as signalR from '@microsoft/signalr';
import {Store} from '@ngrx/store';
import {MultiMessageEnum} from '@app/services/multi-message-enum';
import {onSignalRStatus, onSignalVersionDiff} from '@app/store/client-slices/signal-r/signal-r.actions';
import {AppConfigService} from '@app/utils/services/config/app-config.service';
import {MultiMessage} from '@assets/contract-faxe/multi-message';
import {addManyFenceResult} from '@app/store/faxe/fence-result/fence-result.actions';
import {MultiMessageOperationEnum} from '@app/services/multi-message-operation-enum';
import {FenceResult} from '@app/rest-client/faxe/models/fence-result';
import {CacheObject} from '@app/rest-client/faxe/models/cache-object';
import {addManyRiderEvents} from '@app/store/faxe/rider-event/rider-event.actions';
import {RiderEvent} from '@app/rest-client/faxe/models/rider-event';
import { Rider } from '@app/rest-client/faxe/models/rider';
import { addManyRider, addRider, updateManyRider } from '@app/store/faxe/rider/rider.actions';
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private readonly baseUrl: string = AppConfigService.environmentConfigData.faxeChatHub;
  private connection;
  private versionMap: Map<string, number> = new Map<string, number>();

  constructor(private readonly store: Store,
              private readonly ngZone: NgZone) {
  }

  init() {
    console.log('Init signal r: ' + this.baseUrl);
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 60000) {
            console.log('retryContext elapsedMilliseconds < 60 ' + retryContext.elapsedMilliseconds);
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            const w1 = Math.random() * 10000;
            console.log('wait... ' + w1);
            return w1;
          }
          console.log('retryContext elapsedMilliseconds > 60 ' + retryContext.elapsedMilliseconds);
          const w2 = Math.random() * 20000;
          console.log('wait... ' + w2);
          return w2;
        }
      })
      .build();

    this.connection.onreconnected((connectionId) => {
      console.log('Signal R reconnected with connectionid ' + connectionId);
      this.ngZone.run(() => {
        this.connectionReastablished(connectionId, this.connection.state);
      });
    });

    this.connection.onreconnecting((error) => {
      console.log('Signal R error: ' + error);
      this.ngZone.run(() => {
        this.connectionReConnection(error?.name, error?.message, error?.stack, this.connection.state);
      });
    });

    this.connection.on('ReceivedMessage', (user, msg) => {
      const multiMessage: MultiMessage = JSON.parse(msg);
      const data = JSON.parse(multiMessage?.data);
      if (multiMessage?.dataType && data) {
        this.handleMessageInNgZone(multiMessage.dataType, data, multiMessage.operation, multiMessage.version);
      }
    });

    this.connection.start();

    this.ngZone.run(() => {
      this.store.dispatch(
        onSignalRStatus(
          {model: {status: '-', action: 'start'}}));
    });
  }

  handleMessageInNgZone(dataType: string, data: any, operation: number, version: number) {
    this.ngZone.run(() =>
      this.handleMessage(dataType, data, operation, version));
  }

  handleMultiMessageDataString(cacheObjectList: CacheObject[]) {
    cacheObjectList.forEach(cacheObject => {
      const multiMessage: MultiMessage = JSON.parse(cacheObject?.data);
      this.handleMessage(multiMessage.dataType, multiMessage.data, multiMessage.operation, multiMessage.version);
    });
  }

  handleMessage(dataType: string, data: any, operation: number, version: number) {
    const currentVersion = this.validateVersion(dataType, version);

    console.log(`received message. ${dataType}, operation ${operation},
       version ${version} (${currentVersion === 0 ? 'OK' : (currentVersion - version)})`, data);

    this.store.dispatch(
      onSignalRStatus(
        {model: {status: 'connected', action: 'message received'}}));

    if (currentVersion !== 0) {
      this.store.dispatch(
        onSignalRStatus(
          {model: {status: 'connected', action: 'message received', errorMessage: 'version diff'}}));
      this.store.dispatch(
        onSignalVersionDiff(
          {multiMessageEnum: dataType, currentVersion}));
      return;
    }

    switch (dataType) {
      case MultiMessageEnum.riderEvent:
        this.handleRiderEvent(operation, data);
        break;
      case MultiMessageEnum.rider:
        this.handleRider(operation, data);
        break;
      case MultiMessageEnum.resultCode:
        break;
      case MultiMessageEnum.raceClass:
        break;
      case MultiMessageEnum.race:
        break;
      case MultiMessageEnum.fenceResult:
        this.handleFenceResult(operation, data);
        break;
      case MultiMessageEnum.fence:
        break;
    }
  }

  handleRiderEvent(operation: any, riderEvents: RiderEvent[]) {
    switch (operation) {
      case MultiMessageOperationEnum.add:
        this.addManyRiderEvent(riderEvents);
        break;
      case MultiMessageOperationEnum.update:
        this.notImplemented(operation, 'FenceResult');
        break;
      case MultiMessageOperationEnum.delete:
        this.notImplemented(operation, 'FenceResult');
        break;
    }
  }

  handleRider(operation: any, riders: Rider[]) {
    switch (operation) {
      case MultiMessageOperationEnum.add:
        this.addManyRiders(riders);
        break;
      case MultiMessageOperationEnum.update:
        this.updateManyRiders(riders);
        break;
      case MultiMessageOperationEnum.delete:
        this.notImplemented(operation, 'FenceResult');
        break;
    }
  }

  handleFenceResult(operation: any, fenceResult: FenceResult[]) {
    switch (operation) {
      case MultiMessageOperationEnum.add:
        this.addManyFenceResult(fenceResult);
        break;
      case MultiMessageOperationEnum.update:
        this.notImplemented(operation, 'FenceResult');
        break;
      case MultiMessageOperationEnum.delete:
        this.notImplemented(operation, 'FenceResult');
        break;
    }
  }

  validateVersion = (dataType: string, newVersion: number): number => {
    if (!this.versionMap.has(dataType)) {
      this.versionMap.set(dataType, newVersion - 1);
    }

    const currentVersion = this.versionMap.get(dataType);

    if(currentVersion === newVersion){
      return 0;
    }

    if (currentVersion + 1 !== newVersion) {
      return currentVersion;
    }
    this.versionMap.set(dataType, newVersion);
    return 0;
  };

  addManyFenceResult = (fenceResult: FenceResult[]): void => {
    this.store.dispatch(
      addManyFenceResult(
        {
          models: fenceResult.map(d => ({
           ...d,
          })), issuerStack: ['SignalRService']
        }));
  };

  addManyRiders = (riders: Rider[]): void => {
    this.store.dispatch(
      addManyRider(
        {
          models: riders,
          issuerStack: ['SignalRService']
        }));
  };

  updateManyRiders = (riders: Rider[]): void => {
    this.store.dispatch(
      updateManyRider(
        {
          models: riders,
          issuerStack: ['SignalRService']
        }));
  };

  addManyRiderEvent = (riderEvents: RiderEvent[]): void => {
    this.store.dispatch(
      addManyRiderEvents(
        {
          models: riderEvents.map(d => ({
          ...d
          })), issuerStack: ['SignalRService']
        }));
  };

  connectionReastablished(connectionId: string, status: string) {
    this.store.dispatch(
      onSignalRStatus(
        {model: {connectionId, status, action: 'connectionReastablished'}}));
  }

  connectionReConnection(errorName: string, errorMessage: string, errorStack: string, status: string) {
    this.store.dispatch(
      onSignalRStatus(
        {model: {errorName, errorMessage, errorStack, status, action: 'reconnecting'}}));
  }

  notImplemented(operation: any, dataType: string) {
    console.log('Not implemented', operation, dataType);
  }

}
