import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {catchError, finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {OAuthModuleConfig, OAuthService} from 'angular-oauth2-oidc';
import {HttpStatusService} from '@app/utils/services/http-status/http-status.service';
import {ErrorMessageUtilService} from '@app/utils/services/error-message/error-message-util.service';
import {LocalUrls} from '@assets/contract-faxe/local-urls';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private loadingCalls = 0;

  constructor(
    private errorMessageUtilService: ErrorMessageUtilService,
    private oauthService: OAuthService,
    private httpStatusService: HttpStatusService,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.changeStatus(true, request.method);
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (!error.url.endsWith(LocalUrls.deployment)) {
            this.errorMessageUtilService.getAction(error);
          }
        }
        throw error;
      }),
      finalize(() => {
        this.changeStatus(false, request.method);
      })
    );
  }

  private changeStatus(loading: boolean, method: string): void {
    if (['POST', 'PUT', 'DELETE', 'PATCH', 'GET'].indexOf(method) > -1) {
      if (loading) {
        this.loadingCalls++;
      } else {
        this.loadingCalls--;
      }
      this.httpStatusService.loading = this.loadingCalls > 0;
    }
  }

}
