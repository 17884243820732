import {Component, Input} from '@angular/core';

@Component({
    selector: 'faxe-show-more',
    templateUrl: './show-more.component.html',
    styleUrls: ['./show-more.component.scss'],
    standalone: false
})
export class ShowMoreComponent {

  @Input() lista: string[] = [];
  @Input() antalAttVisa: number;
  @Input() toggleMoreText: string;
  @Input() toggleLessText: string;
  @Input() listEmptyText: string;
  @Input() break = true;
  @Input() horizontal: boolean;
  visaAlla: boolean;

  toggle(): void {
    this.visaAlla = !this.visaAlla;
  }

  last(row: string): boolean {
    return this.lista.indexOf(row) === this.lista.length - 1;
  }

}
