import {AfterContentInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[faxeAutofocus]',
    standalone: false
})
export class AutofocusDirective implements AfterContentInit {


  doFocus: boolean;

  constructor(private el: ElementRef) {
  }

  @Input() set appAutofocus(value: boolean) {
    this.doFocus  = value;
  }

  public ngAfterContentInit() {
    this.setFocus(this.doFocus);
  }

  public setFocus(focus: boolean) {
    setTimeout(() => {
      if (focus) {
        this.el.nativeElement.focus();
      }
    }, 0);
  }

}
