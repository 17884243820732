import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogStoreEnums} from '@app/store/client-slices/dialog/dialog-store.enums';
import {DialogFacadeService} from '@app/components/utils/confirmation-dialog/confirm-dialog.facade.service';
import {ErrorMessageCodeEnum} from '@app/utils/services/error-message/error-message-code.enum';
import {ErrorMessageUtilService} from '@app/utils/services/error-message/error-message-util.service';
import {DialogStoreState} from '@app/store/client-slices/dialog/dialog-store-state';
import {IDialogResult} from '@app/components/utils/confirmation-dialog/IDialogResult';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

export enum Buttons {
  stang = 'Stäng',
  fortsatt = 'Fortsätt',
  avbryt = 'Avbryt',
  taBort = 'Ta bort',
  ctrlS = 'CtrlS',
}

export class ConfirmationMessage {
  title?: string;
  message?: string;
  primaryButton: Buttons | string;
  secondaryButton?: Buttons | string;
  bulletList?: Array<string>;
  messageLista?: Array<string>;
  correlationId?: string;
  useInput?: boolean;
}

@Component({
    selector: 'faxe-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: false
})
export class ConfirmationDialogComponent<R> implements OnInit, OnDestroy {
  public buttons = Buttons;
  formGroup: UntypedFormGroup = this.formBuilder.group({});
  private unsubscribe$ = new Subject<void>();
  private dialogSate: DialogStoreState;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent<R>, IDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationMessage,
    private facadeService: DialogFacadeService
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.dialogRef
      .keydownEvents()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: KeyboardEvent) => {

        if (event.key === 'Enter') {
          this.onPrimaryButton();
        }

        if (event.key === 'Escape') {
          this.onSecondaryButton();
        }
        if (event.key === 's' && event.ctrlKey) {
          event.preventDefault();
          this.onCtrlS();
        }
      });

    if (this.data?.correlationId) {
      this.facadeService
        .hamtaDialogStoreStatus()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((dialogState) => this.handleDialogState(dialogState));

      this.facadeService.initDialog();
      this.facadeService.openDialog();
    }
  }

  handleDialogState(dialogState: DialogStoreState) {
    if (
      (dialogState.status === DialogStoreEnums.stangdialog ||
        dialogState.status === DialogStoreEnums.operationmisslyckades) &&
      this.data.correlationId === dialogState.correlationId
    ) {
      this.dialogSate = dialogState;
      if (dialogState.errorMessageCodeEnum) {
        this.data.message = ErrorMessageUtilService.getErrorMessage({
          code: ErrorMessageCodeEnum.i000,
        }).text;
        this.data.primaryButton = 'Tillbaka';
      } else {
        this.dialogRef.close({
          button: 'close',
        });
      }
    }
  }

  onCtrlS(): void {
    if (this.data.secondaryButton === Buttons.avbryt) {
      this.close({
        button: Buttons.ctrlS,
        confirmationMessage: this.data,
        dialogStoreState: this.dialogSate,
        input: this.formGroup.controls.input.value
      });
    }
  }

  onSecondaryButton(): void {
    this.close({
      button: this.data.secondaryButton,
      confirmationMessage: this.data,
      dialogStoreState: this.dialogSate,
    });
  }

  onPrimaryButton() {
    if (this.data.useInput) {
      if (!this.formGroup.invalid) {
        this.close({
          button: this.data.primaryButton,
          confirmationMessage: this.data,
          dialogStoreState: this.dialogSate,
          input: this.formGroup.controls.input.value.value
        });
      }
    } else {
      this.close({
        button: this.data.primaryButton,
        confirmationMessage: this.data,
        dialogStoreState: this.dialogSate,
      });
    }
  }

  close(dialogResult: IDialogResult): void {
    this.dialogRef.close(dialogResult);
  }

  ngOnDestroy(): void {
    if (this.data?.correlationId) {
      this.facadeService.closeDialog();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
