import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY} from 'rxjs';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {FenceResultsService} from '@app/rest-client/faxe/services/fence-results.service';
import {onSignalVersionDiff} from '@app/store/client-slices/signal-r/signal-r.actions';
import {selectSignalRStatus} from '@app/store/client-slices/signal-r/signal-r-store-selectors';
import {MemoryCacheService} from '@app/rest-client/faxe/services/memory-cache.service';
import {SignalRService} from '@app/services/signal-r.service';

@Injectable()
export class SignalRStoreEffects {

  onSignalVersionDiffEffect = createEffect(() => this.actions$.pipe(
      ofType(onSignalVersionDiff),
      withLatestFrom(this.store.select(selectSignalRStatus)),
      exhaustMap(([action, _]) => {
        this.memoryCacheService
          .memorycacheDatatypeDatatypeFromVersionGet({datatype: action.multiMessageEnum, fromVersion: action.currentVersion})
          .subscribe(data => {
            this.signalRService.handleMultiMessageDataString(data);
          });
        return EMPTY;
      }),
      catchError(() => EMPTY)
    ),
    {dispatch: false}
  );

  constructor(
    private service: FenceResultsService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
    private memoryCacheService: MemoryCacheService,
    private signalRService: SignalRService
  ) {
  }
}
