import {createSelector, MemoizedSelector} from '@ngrx/store';
import {Fence} from '@app/rest-client/faxe/models/fence';
import {selectAllFence} from '@app/store/faxe/fences/fence.reducer';
import {getFenceState} from '@app/store/faxe/faxe-selectors';



// eslint-disable-next-line @typescript-eslint/ban-types
export const selectFenceAll: MemoizedSelector<object, Fence[]> = createSelector(
  getFenceState,
  selectAllFence
);

export const selectFence = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, Fence[]> =>
  createSelector(
    getFenceState,
    selectFenceAll,
    (state, all) => all
  );


export const selectFenceById = (fenceGuid: string):
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, Fence> =>
  createSelector(
    getFenceState,
    selectFenceAll,
    (state, all) => all
        .find(x => x.fenceGuid === fenceGuid)
  );
