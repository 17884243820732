import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule, isDevMode} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {HeaderModule} from './components/header/header.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {RootStoreModule} from '@app/store/root-store.module';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {AppRoutingModule} from '@app/app-routing.module';
import {OAuthModule} from 'angular-oauth2-oidc';
import {AppHttpInterceptor} from '@app/app-http-interceptor';
import {AppRequestTimeoutInterceptor} from '@app/app-request-timeout-interceptor';
import {LandingModule} from '@app/components/landing/landing.module';
import {AppErrorHandler} from '@app/app-error-handler';
import {InputFormModule} from '@app/components/shared/input-form/input-form.module';
import {ReactiveFormsModule} from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RiderSelectChipComponent } from './components/common/rider-select-chip/rider-select-chip.component';
import {ChipListModule} from "@app/components/shared/chip-list/chip-list.module";


@NgModule({ declarations: [
        AppComponent,
        RiderSelectChipComponent
    ],
    bootstrap: [AppComponent], imports: [
        // Allowedurls is configured later when config has arrived.
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
            },
        }),
        BrowserModule,
        BrowserAnimationsModule,
        HeaderModule,
        LandingModule,
        RouterModule,
        RootStoreModule,
        AppRoutingModule,
        InputFormModule,
        ReactiveFormsModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        ChipListModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppRequestTimeoutInterceptor, multi: true },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
