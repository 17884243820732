<mat-accordion *ngIf="!viewMode">
  <mat-expansion-panel hideToggle #thePanel>
    <mat-expansion-panel-header>
      <mat-icon *ngIf="thePanel.expanded" svgIcon="expand_circle_up"></mat-icon>
      <mat-icon *ngIf="!thePanel.expanded" svgIcon="expand_circle_down"></mat-icon>
      <mat-panel-title>Visas</mat-panel-title>
      <mat-panel-description>{{selectedClassNameList}}</mat-panel-description>
    </mat-expansion-panel-header>

    <div class="raceClass">
      <faxe-chip-list
        [preSelectedKeys]=[allClassId]
        (itemSelectedEvent)="classSelected($event)"
        (itemUnSelectedEvent)="classUnselected($event)"
        (itemPreSelectedEvent)="classPreSelected($event)"
        itemClassDefault=true;
        formCtrlName="raceClass"
        [parentFormGroup]="formGroup"
        [itemsSet]="raceClassDataItem">
      </faxe-chip-list>
    </div>
  </mat-expansion-panel>
  <br>
</mat-accordion>
<div *ngIf="!riderDataItems || !riderDataItems?.length" class="container">
  <mat-progress-bar mode="buffer"></mat-progress-bar>
  <h1 class = "wait-text">Inväntar start av klass</h1>
  <img class="logga" src="./assets/images/sm-logga-2024-tr.png">
</div>

<mat-card appearance="outlined" class="rider" *ngFor="let rider of riderDataItems">
  <mat-card-header>
    <img mat-card-avatar [src]=logoSrc(rider.value.rider.riderId)>
    <mat-card-title class="card-container-left">{{rider.value.rider.riderName}}</mat-card-title>
    <mat-card-title
      class="card-container-right">{{riderTimeMap?.has(rider.key) ? riderTimeMap.get(rider.key) : ''}}</mat-card-title>
    <mat-card-subtitle>{{rider.value.rider.horseCombinationNumber}}
      - {{rider.value.rider.horseName}}, {{rider.value.rider.clubName}}  </mat-card-subtitle>
  </mat-card-header>

  <div class="p">
    <span *ngFor="let fenceWithResult of rider.value.fenceWithResult" [ngClass]="{
      'ok': fenceWithResult.resultCodeGuid === 1,
      'na': fenceWithResult.resultCodeGuid === -1,
      'nok': fenceWithResult.resultCodeGuid > 1,
      }">
    </span>
  </div>

  <div *ngIf="!viewMode">
    <faxe-collapisble-section
      [collapsed]=isCollapsed(rider.value.rider.riderId)
      (collapsToggle)=handleCollapse($event,rider.value.rider.riderId)
    >
      <faxe-table
        [data]=tableDataMap.get(rider.value.rider.riderId)
      >
      </faxe-table>
    </faxe-collapisble-section>

  </div>
</mat-card>
