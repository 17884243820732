import { Component } from '@angular/core';

@Component({
    selector: 'faxe-header',
    template: `
    <header>
      <faxe-banner></faxe-banner>
    </header>
  `,
    standalone: false
})
export class HeaderComponent {}
