import {Fence} from '@app/rest-client/faxe/models/fence';
import {ResultCode} from '@app/rest-client/faxe/models/result-code';
import {Table} from '@app/components/shared/table/model/table';
import {RiderClassFence} from '@app/components/fence-result-audience/model/RiderClassFence';
import {FenceResult} from '@app/rest-client/faxe/models/fence-result';
import {RiderDataItem} from '@app/components/fence-result-audience/model/rider-data-item';
import {Row} from '@app/components/shared/table/model/row';
import {CellMeta} from '@app/components/shared/table/model/cellMeta';
import {Reported} from '@app/components/race-result/model/reported';
import {isEmpty} from '@app/utils/functions/common-functions';
import {RiderView} from '@app/services/rider-view/model/rider-view';

export const mapTable = (
  rider: RiderView,
  fenceClassMap: Map<string, Fence[]>,
  resultCodes: ResultCode[]): Table<RiderDataItem> => ({
  showPaginator: false,
  slim: true,
  columns: [
    {header: '', property: 'fenceName'},
    {header: '', property: 'result'},
  ],
  rows: toRow(rider.fenceResults, fenceClassMap.get(rider.raceClassGuid), resultCodes)
});

export const toRow = (
  fenceResults: FenceResult[],
  fenceInClass: Fence[],
  resultCodes: ResultCode[],
): Row<RiderDataItem>[] => fenceResults ? fenceInClass.map(classFence => {

    const rowT: RiderDataItem = {
      id: classFence.fenceGuid,
      cellMetaMap: Map<string, CellMeta>
    };

    const reported = mapReported(classFence, fenceResults.filter(fr => fr.fenceGuid === classFence.fenceGuid), resultCodes);

    rowT.cellMetaMap = new Map<string, CellMeta>();
    rowT.fenceName = `${classFence.fenceName} - ${classFence.fenceDescription ? classFence.fenceDescription : 'hinder'}`;
    rowT.cellMetaMap.set('result', {cellClass: reported.type});
    rowT.result = reported.value;

    const rowRiderDataItem: Row<RiderDataItem> = ({
      row: rowT,
      highligt: '',
    });

    return rowRiderDataItem;
  }
) : undefined;


export const mapReported =
  (fence: Fence,
   fenceResult: FenceResult[],
   resultCodes: ResultCode[]): Reported => {

    const resultCodeFence = fenceResult.map(r => resultCodes.find(c => c.resultCodeGuid === r.resultCodeGuid));

    if (isEmpty(resultCodeFence)) {
      return {type: 'pending', value: fence.fenceName};
    }

    if (resultCodeFence.find(c => !c.isFault)) {
      return {type: 'good-slim', value: true};
    } else {
      return {type: 'fault-slim', value: resultCodeFence.map(r => r.name)};
    }
  };

export const mapRiderClassFence =
  (riderView: RiderView, fenceClassMap: Map<string, Fence[]>): RiderClassFence => ({
  ...riderView,
  fenceWithResult:
    fenceClassMap?.get(riderView.raceClassGuid)
      .map(f => ({...f, resultCodeGuid: resultCodeId(riderView.fenceResults, f.fenceGuid)})),
});

export const resultCodeId = (fenceResult: FenceResult[], fenceGuid: string): string => {
  const result = fenceResult.find(r => r.fenceGuid === fenceGuid);

  if (!result) {
    return '-1';
  }

  return result.resultCodeGuid;
};
