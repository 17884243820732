/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FenceResult } from '../models/fence-result';
import { FenceResultPost } from '../models/fence-result-post';

@Injectable({
  providedIn: 'root',
})
export class FenceResultsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fenceresults
   */
  static readonly FenceresultsPath = '/fenceresults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fenceresults()` instead.
   *
   * This method doesn't expect any request body.
   */
  fenceresults$Response(params?: {
  }): Observable<StrictHttpResponse<Array<FenceResult>>> {

    const rb = new RequestBuilder(this.rootUrl, FenceResultsService.FenceresultsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FenceResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fenceresults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fenceresults(params?: {
  }): Observable<Array<FenceResult>> {

    return this.fenceresults$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FenceResult>>) => r.body as Array<FenceResult>)
    );
  }

  /**
   * Path part for operation fenceResultPost
   */
  static readonly FenceResultPostPath = '/fenceresults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fenceResultPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fenceResultPost$Response(params: {
    body: Array<FenceResultPost>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FenceResultsService.FenceResultPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fenceResultPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fenceResultPost(params: {
    body: Array<FenceResultPost>
  }): Observable<void> {

    return this.fenceResultPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fenceresultsbyrace
   */
  static readonly FenceresultsbyracePath = '/races/{raceGuid}/fenceresults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fenceresultsbyrace()` instead.
   *
   * This method doesn't expect any request body.
   */
  fenceresultsbyrace$Response(params: {
    raceGuid: string;
  }): Observable<StrictHttpResponse<Array<FenceResult>>> {

    const rb = new RequestBuilder(this.rootUrl, FenceResultsService.FenceresultsbyracePath, 'get');
    if (params) {
      rb.path('raceGuid', params.raceGuid, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FenceResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fenceresultsbyrace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fenceresultsbyrace(params: {
    raceGuid: string;
  }): Observable<Array<FenceResult>> {

    return this.fenceresultsbyrace$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FenceResult>>) => r.body as Array<FenceResult>)
    );
  }

  /**
   * Path part for operation fenceresultsbyraceandhcn
   */
  static readonly FenceresultsbyraceandhcnPath = '/races/{raceGuid}/fenceresults/{horseCombinationNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fenceresultsbyraceandhcn()` instead.
   *
   * This method doesn't expect any request body.
   */
  fenceresultsbyraceandhcn$Response(params: {
    raceGuid: string;
    horseCombinationNumber: number;
  }): Observable<StrictHttpResponse<Array<FenceResult>>> {

    const rb = new RequestBuilder(this.rootUrl, FenceResultsService.FenceresultsbyraceandhcnPath, 'get');
    if (params) {
      rb.path('raceGuid', params.raceGuid, {"style":"simple"});
      rb.path('horseCombinationNumber', params.horseCombinationNumber, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FenceResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fenceresultsbyraceandhcn$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fenceresultsbyraceandhcn(params: {
    raceGuid: string;
    horseCombinationNumber: number;
  }): Observable<Array<FenceResult>> {

    return this.fenceresultsbyraceandhcn$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FenceResult>>) => r.body as Array<FenceResult>)
    );
  }

  /**
   * Path part for operation deleteFenceResult
   */
  static readonly DeleteFenceResultPath = '/fenceresult/{horseCombinationNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFenceResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFenceResult$Response(params: {
    horseCombinationNumber: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FenceResultsService.DeleteFenceResultPath, 'delete');
    if (params) {
      rb.path('horseCombinationNumber', params.horseCombinationNumber, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFenceResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFenceResult(params: {
    horseCombinationNumber: number;
  }): Observable<void> {

    return this.deleteFenceResult$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
