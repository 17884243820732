import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {groupBy, isNotEmpty} from '@app/utils/functions/common-functions';
import {Fence} from '@app/rest-client/faxe/models/fence';
import {fetchAllFences, postFence} from '@app/store/faxe/fences/fence.actions';
import {selectFence} from '@app/store/faxe/fences/fence.selectos';

@Injectable({
  providedIn: 'root'
})
export class FenceFacadeService {

    constructor(private store: Store<RootState>) {
  }

  fetchFence(issuer: string): Observable<Fence[]> {
    this.store.dispatch(
      fetchAllFences(
        {issuerStack: [issuer]}));

    return this.store.pipe(
      select(selectFence()),
      filter((data) => isNotEmpty(data)));
  }

  fetchFenceClassMap(issuer: string): Observable<Map<string, Fence[]>> {
    this.store.dispatch(
      fetchAllFences(
        {issuerStack: [issuer]}));

    return this.store.pipe(
      select(selectFence()),
      filter((data) => isNotEmpty(data)),
      map(fences => groupBy(
        fences,
        (f) => f.raceClassGuid,
        (f) => f.renumberedSequence)));
  }

  addFence(fences: Fence[], issuer: string): void {
    this.store.dispatch(
      postFence(
        {fences, issuerStack: [issuer]}));
  }

}
