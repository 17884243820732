{
  "$schema": "./error-message.schema.json",
  "errorCodes": [
    {
      "code": "I-000",
      "title": "Unknown",
      "text": "Okänt fel",
      "severity": "F",
      "type": "B",
      "buttons": []
    },
    {
      "code": "I-001",
      "title": "Ange tävlingslösenord",
      "text": "",
      "severity": "I",
      "type": "M",
      "buttons": ["stäng"]
    },
    {
      "code": "I-002",
      "title": "Operation fail",
      "text": "Operation misslyckades",
      "severity": "F",
      "type": "B",
      "buttons": []
    }
  ]
}
