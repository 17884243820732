import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFormComponent } from './input-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {HtmlDisplayModule} from '@app/components/utils/html/html-display.module';
import {PipesModule} from '@app/components/utils/pipes/pipes.module';
import {AutoFocusDirectiveModule} from '@app/components/shared/input-form/auto-focus-directive.module';



@NgModule({
  declarations: [InputFormComponent],
  exports: [
    InputFormComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    HtmlDisplayModule,
    PipesModule,
    AutoFocusDirectiveModule,
    ]
})
export class InputFormModule { }
