import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppRouteGuard} from './app-route.guard';
import {AppRoutes} from './app-routes.interface';
import {MatDialogModule} from '@angular/material/dialog';
import {LandingComponent} from '@app/components/landing/landing.component';
import {AppRouteGuardHome} from '@app/app-route-home.guard';
import {PageId} from '@app/constants/page-id';

const routes: AppRoutes = [
  {
    path: '', canActivate: [AppRouteGuardHome], component: LandingComponent,
  },
  {
    path: `${PageId.fenceResultAudience}`,
    loadChildren: () =>
      import('./pages/race-result-audience/race-result-audience.module').then(m => m.RaceResultAudienceModule),
    canActivateChild: [AppRouteGuard],
  },
  {
    path: `${PageId.raceResult}`,
    loadChildren: () =>
      import('./pages/race-result/race-result.module').then((m) => m.RaceResultModule),
    canActivateChild: [AppRouteGuard],
  },
  {
    path: `${PageId.finish}`,
    loadChildren: () =>
      import('./pages/finish/finish.module').then((m) => m.FinishModule),
    canActivateChild: [AppRouteGuard],
  },
  {
    path: `${PageId.start}`,
    loadChildren: () =>
      import('./pages/start/start.module').then((m) => m.StartModule),
    canActivateChild: [AppRouteGuard],
  },
  {
    path: `${PageId.fenseResult}`,
    loadChildren: () =>
      import('./pages/fense-result/fense-result.module').then((m) => m.FenseResultModule),
    canActivateChild: [AppRouteGuard],
  },
  {
    path: `${PageId.createRace}`,
    loadChildren: () =>
      import('./pages/admin/race-page/race-page.module').then((m) => m.RacePageModule),
    canActivateChild: [AppRouteGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'disabled', useHash: false}),
    MatDialogModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
