/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Rider } from '../models/rider';
import { RiderResult } from '../models/rider-result';

@Injectable({
  providedIn: 'root',
})
export class RidersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation ridersbyrace
   */
  static readonly RidersbyracePath = '/races/{raceGuid}/riders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ridersbyrace()` instead.
   *
   * This method doesn't expect any request body.
   */
  ridersbyrace$Response(params: {
    raceGuid: string;
  }): Observable<StrictHttpResponse<Array<Rider>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RidersbyracePath, 'get');
    if (params) {
      rb.path('raceGuid', params.raceGuid, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rider>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ridersbyrace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ridersbyrace(params: {
    raceGuid: string;
  }): Observable<Array<Rider>> {

    return this.ridersbyrace$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rider>>) => r.body as Array<Rider>)
    );
  }

  /**
   * Path part for operation riders
   */
  static readonly RidersPath = '/riders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riders()` instead.
   *
   * This method doesn't expect any request body.
   */
  riders$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Rider>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RidersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rider>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `riders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riders(params?: {
  }): Observable<Array<Rider>> {

    return this.riders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rider>>) => r.body as Array<Rider>)
    );
  }

  /**
   * Path part for operation ridersPost
   */
  static readonly RidersPostPath = '/riders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ridersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ridersPost$Response(params: {
    body: Array<Rider>
  }): Observable<StrictHttpResponse<Array<Rider>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RidersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rider>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ridersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ridersPost(params: {
    body: Array<Rider>
  }): Observable<Array<Rider>> {

    return this.ridersPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rider>>) => r.body as Array<Rider>)
    );
  }

  /**
   * Path part for operation ridersisreportable
   */
  static readonly RidersisreportablePath = '/riders/isReportable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ridersisreportable()` instead.
   *
   * This method doesn't expect any request body.
   */
  ridersisreportable$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Rider>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RidersisreportablePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rider>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ridersisreportable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ridersisreportable(params?: {
  }): Observable<Array<Rider>> {

    return this.ridersisreportable$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rider>>) => r.body as Array<Rider>)
    );
  }

  /**
   * Path part for operation riderresultsGet
   */
  static readonly RiderresultsGetPath = '/riderresults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riderresultsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderresultsGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RiderResult>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RiderresultsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiderResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `riderresultsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderresultsGet(params?: {
  }): Observable<Array<RiderResult>> {

    return this.riderresultsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiderResult>>) => r.body as Array<RiderResult>)
    );
  }

  /**
   * Path part for operation racesRaceGuidRiderresultsGet
   */
  static readonly RacesRaceGuidRiderresultsGetPath = '/races/{raceGuid}/riderresults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `racesRaceGuidRiderresultsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  racesRaceGuidRiderresultsGet$Response(params: {
    raceGuid: string;
  }): Observable<StrictHttpResponse<Array<RiderResult>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RacesRaceGuidRiderresultsGetPath, 'get');
    if (params) {
      rb.path('raceGuid', params.raceGuid, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiderResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `racesRaceGuidRiderresultsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  racesRaceGuidRiderresultsGet(params: {
    raceGuid: string;
  }): Observable<Array<RiderResult>> {

    return this.racesRaceGuidRiderresultsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiderResult>>) => r.body as Array<RiderResult>)
    );
  }

  /**
   * Path part for operation racesRaceGuidRiderresultsHorseCombinationNumberGet
   */
  static readonly RacesRaceGuidRiderresultsHorseCombinationNumberGetPath = '/races/{raceGuid}/riderresults/{horseCombinationNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `racesRaceGuidRiderresultsHorseCombinationNumberGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  racesRaceGuidRiderresultsHorseCombinationNumberGet$Response(params: {
    raceGuid: string;
    horseCombinationNumber: number;
  }): Observable<StrictHttpResponse<Array<RiderResult>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RacesRaceGuidRiderresultsHorseCombinationNumberGetPath, 'get');
    if (params) {
      rb.path('raceGuid', params.raceGuid, {"style":"simple"});
      rb.path('horseCombinationNumber', params.horseCombinationNumber, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiderResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `racesRaceGuidRiderresultsHorseCombinationNumberGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  racesRaceGuidRiderresultsHorseCombinationNumberGet(params: {
    raceGuid: string;
    horseCombinationNumber: number;
  }): Observable<Array<RiderResult>> {

    return this.racesRaceGuidRiderresultsHorseCombinationNumberGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiderResult>>) => r.body as Array<RiderResult>)
    );
  }

  /**
   * Path part for operation setHandled
   */
  static readonly SetHandledPath = '/riders/sethandled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setHandled()` instead.
   *
   * This method doesn't expect any request body.
   */
  setHandled$Response(params: {
    riderId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.SetHandledPath, 'post');
    if (params) {
      rb.query('riderId', params.riderId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setHandled$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setHandled(params: {
    riderId: string;
  }): Observable<void> {

    return this.setHandled$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setUnhandled
   */
  static readonly SetUnhandledPath = '/riders/setunhandled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUnhandled()` instead.
   *
   * This method doesn't expect any request body.
   */
  setUnhandled$Response(params: {
    riderId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.SetUnhandledPath, 'post');
    if (params) {
      rb.query('riderId', params.riderId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUnhandled$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setUnhandled(params: {
    riderId: string;
  }): Observable<void> {

    return this.setUnhandled$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteRider
   */
  static readonly DeleteRiderPath = '/rider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRider()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRider$Response(params: {
    riderGuid: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.DeleteRiderPath, 'delete');
    if (params) {
      rb.query('riderGuid', params.riderGuid, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRider(params: {
    riderGuid: string;
  }): Observable<void> {

    return this.deleteRider$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
