import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FenceFacadeService} from '@app/services/fence/fence-facade.service';
import {FenceResultFacadeService} from '@app/services/fence-result/fence-result-facade.service';
import {ResultCodeFacadeService} from '@app/services/result-code/result-code-facade.service';
import {RiderFacadeService} from '@app/services/rider/riders-facade.service';
import {DataItem} from '@app/components/shared/model/data-item';
import {RiderView} from '@app/services/rider-view/model/rider-view';

@Component({
    selector: 'faxe-rider-select-chip',
    templateUrl: './rider-select-chip.component.html',
    styleUrls: ['./rider-select-chip.component.scss'],
    standalone: false
})
export class RiderSelectChipComponent {

  formGroup: UntypedFormGroup = this.formBuilder.group({});
  riderDataItem: DataItem<RiderView>[];


  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly fenceFacadeService: FenceFacadeService,
              private readonly fenceResultFacadeService: FenceResultFacadeService,
              private readonly resultCodeFacadeService: ResultCodeFacadeService,
              private readonly riderFacadeService: RiderFacadeService,
  ) {
  }

  onRiderSelected($event: DataItem<RiderView>) {

  }

  onRiderLastUnSelected() {

  }
}
