import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { clientSlicesReducers } from './client-slices-store-model';
import { DialogStoreEffects } from './dialog/dialog-store-effects';

@NgModule({ imports: [CommonModule,
        StoreModule.forFeature('clientSlices', clientSlicesReducers),
        EffectsModule.forFeature([
            DialogStoreEffects,
        ])], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ClientSlicesStoreModule {}
