/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClubLogo } from '../models/club-logo';
import { ClubLogoList } from '../models/club-logo-list';

@Injectable({
  providedIn: 'root',
})
export class ClubLogoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getClubLogotype
   */
  static readonly GetClubLogotypePath = '/clublogotypes/{logoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClubLogotype()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClubLogotype$Response(params: {
    logoId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClubLogoService.GetClubLogotypePath, 'get');
    if (params) {
      rb.path('logoId', params.logoId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClubLogotype$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClubLogotype(params: {
    logoId: number;
  }): Observable<void> {

    return this.getClubLogotype$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getClublogotypesList
   */
  static readonly GetClublogotypesListPath = '/clublogotypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClublogotypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClublogotypesList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ClubLogoList>>> {

    const rb = new RequestBuilder(this.rootUrl, ClubLogoService.GetClublogotypesListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClubLogoList>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClublogotypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClublogotypesList(params?: {
  }): Observable<Array<ClubLogoList>> {

    return this.getClublogotypesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClubLogoList>>) => r.body as Array<ClubLogoList>)
    );
  }

  /**
   * Path part for operation clubLogotype
   */
  static readonly ClubLogotypePath = '/clublogotypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clubLogotype()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  clubLogotype$Response(params: {
    clubName: string;
    body?: {
'file': Blob;
}
  }): Observable<StrictHttpResponse<ClubLogo>> {

    const rb = new RequestBuilder(this.rootUrl, ClubLogoService.ClubLogotypePath, 'post');
    if (params) {
      rb.query('clubName', params.clubName, {"style":"form"});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubLogo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clubLogotype$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  clubLogotype(params: {
    clubName: string;
    body?: {
'file': Blob;
}
  }): Observable<ClubLogo> {

    return this.clubLogotype$Response(params).pipe(
      map((r: StrictHttpResponse<ClubLogo>) => r.body as ClubLogo)
    );
  }

  /**
   * Path part for operation deleteClubLogotype
   */
  static readonly DeleteClubLogotypePath = '/clublogotypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteClubLogotype()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteClubLogotype$Response(params: {
    logoId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClubLogoService.DeleteClubLogotypePath, 'delete');
    if (params) {
      rb.query('logoId', params.logoId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteClubLogotype$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteClubLogotype(params: {
    logoId: number;
  }): Observable<void> {

    return this.deleteClubLogotype$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
