<div>
  <form [formGroup]="formGroup">
    <mat-form-field style="width: 100%">
      <mat-label>{{ label }}</mat-label>
      {{ high }}
      <input #inputElement class="big"
             [attr.inputmode]="inputModeType"
             faxeAutofocus
             [appAutofocus]="inputAutoFocus"
             [maxlength]="maxInputlength"
             [placeholder]="placeholder"
             [required]="requiredInput"
             [type]="inputType"
             autocomplete="off"
             formControlName="value"
             matInput
             max="5"
             min="1"
      >
      <mat-hint *ngIf="hint">
        {{ hint }}
      </mat-hint>
    </mat-form-field>
    <mat-error *ngIf="formGroup.get('valid')?.errors?.error">
      {{ formGroup.get('valid')?.errors?.error['msg'] }}
    </mat-error
    >
  </form>
</div>

